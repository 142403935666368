<template>
  <div v-show="items && items.length">
    <section class="section-primary" :class="styleContainer">
      <div class="container">
        <div :class="hasSearchSlot ? 'section-primary__inner' : 'section-primary-row'">
          <HomeContainerCardsText
            :title="cmsContent.title"
            :text="cmsContent.text"
          />
          <slot name="search"></slot>
        </div>
        <div class="top-projects__wrapper">
          <VueSlickCarousel
            v-if="items && items.length > 0"
            :slidesToShow="slidesToShow"
            :slidesToScroll="1"
            class="top-projects__slider responsive"
          >
            <template #prevArrow>
              <button
                class="slider-btn slider-btn__left"
                :style="'background: url('
                  + require('@/assets/images/slider-arrow.svg')
                  + ') no-repeat center;'"
              >
              </button>
            </template>
            <template #nextArrow>
              <button
                class="slider-btn slider-btn__right"
                :style="'background: url('
                  + require('@/assets/images/slider-arrow-right.svg')
                  + ') no-repeat center;'"
              >
              </button>
            </template>
            <slot name="card"></slot>
          </VueSlickCarousel>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';

import HomeContainerCardsText
from '@/views/authorization/components/homeContainers/HomeContainerCardsText.vue';

export default {
  name: 'HomeContainerCards',
  components: {
    VueSlickCarousel,
    HomeContainerCardsText,
  },
  mixins: [],
  props: {
    cmsContent: {
      type: Object,
    },
    categories: {
      type: Array,
    },
    items: {
      type: Array,
    },
    styleContainer: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      slidesToShow: 3,
      hasSearchSlot: false,
      // slideSettings: {
      //   arrows: true,
      //   slidesToShow: 2,
      //   slidesToScroll: 1,
      //   // infinity: false,
      // },
    };
  },
  computed: {},
  created() {
  },
  methods: {},
  mounted() {
    if (window.innerWidth <= 992) {
      this.slidesToShow = 2;
    }
    if (window.innerWidth <= 576) {
      this.slidesToShow = 1;
    }
    window.addEventListener('resize', () => {
      if (window.innerWidth > 992) {
        this.slidesToShow = 3;
      }
      if (window.innerWidth <= 992) {
        this.slidesToShow = 2;
      }
      if (window.innerWidth <= 576) {
        this.slidesToShow = 1;
      }
    });
    this.hasSearchSlot = !!this.$slots.search;
  },
  watch: {},
};
</script>

<style lang="scss" scoped>
::v-deep .slick-arrow {
  width: 50px;
  height: 100px;

  &:before {
    content: '';
  }
}

.top-projects__wrapper .top-projects__slider .slick-list .slick-track .slick-slide div {
  flex: 1;
}

.slider-btn.slider-btn__left {
  left: -50px;
}

.slider-btn.slider-btn__right {
  right: -50px;
}

@media (max-width: 1200px) {
  .slick-arrow {
    height: 75px;
  }
  .slider-btn.slider-btn__left {
    left: -40px;
  }
  .slider-btn.slider-btn__right {
    right: -40px;
  }
}
@media (max-width: 576px) {
  .slider-btn.slider-btn__left {
    left: -25px;
  }
  .slider-btn.slider-btn__right {
    right: -25px;
  }
}
</style>
