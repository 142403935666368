import { ref /* , onMounted */ } from '@vue/composition-api';

import GroupService from '@/services/GroupService';

export default function useGroupRepositories() {
  const groups = ref([]);

  const setGroupTopRepositories = async (searchData = null) => {
    let res = ref([]);
    const data = {};

    data['per-page'] = (searchData && searchData['per-page']) || 50;

    if (searchData) {
      if (searchData.type) data.type = searchData.type;
      if (searchData.style) data.style = searchData.style;
      if (searchData.text) data.text = searchData.text;
      if (searchData.category_id) data.category_id = searchData.category_id;
    }

    try {
      res = await GroupService.groups({ ...data });
      groups.value = res.data;
    } catch (e) {
      groups.value = [];
    }
  };

  const getGroupTopRepositories = async (searchData = null) => {
    let res = ref([]);
    const data = {};

    data['per-page'] = (searchData && searchData['per-page']) || 50;

    if (searchData) {
      if (searchData.type) data.type = searchData.type;
      if (searchData.style) data.style = searchData.style;
      if (searchData.text) data.text = searchData.text;
      if (searchData.category_id) data.category_id = searchData.category_id;
    }

    try {
      res = await GroupService.groupsTop({ ...data });
      return res.data;
    } catch (e) {
      return [];
    }
  };

  const resetGroupRepositories = () => {
    groups.value = [];
  };

  return {
    groups,
    setGroupTopRepositories,
    getGroupTopRepositories,
    resetGroupRepositories,
  };
}
