<template>
  <div>
    <div class="section-primary__title">{{title}}</div>
    <div style="color: #000000">{{text}}</div>
  </div>
</template>
<script>

export default {
  name: 'HomeContainerCardsText',
  components: {},
  mixins: [],
  props: {
    title: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
  },
  data() {
    return {};
  },
  computed: {},
  created() {},
  methods: {},
  mounted() {},
};
</script>

<style lang="scss" scoped>
</style>
