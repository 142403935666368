import { ref /* , onMounted */ } from '@vue/composition-api';

import ProjectService from '@/services/ProjectService';

export default function useProjectRepositories() {
  const projects = ref([]);

  const getProjectTopRepositories = async (searchData = null) => {
    let res = ref([]);
    const data = {};

    data['per-page'] = (searchData && searchData['per-page']) || 50;

    if (searchData) {
      if (searchData.type) data.type = searchData.type;
      if (searchData.text) data.text = searchData.text;
      if (searchData.category_id) data.category_id = searchData.category_id;
    }

    try {
      res = await ProjectService.projectsTop({ ...data });
      projects.value = res.data;
    } catch (e) {
      projects.value = [];
    }
  };

  const resetProjectRepositories = () => {
    projects.value = [];
  };

  const addLikeToProject = async (projectID) => {
    try {
      await ProjectService.addLike(projectID);
      const projectUpdate = projects.value.find((project) => project.id === projectID);

      projectUpdate.like = true;
      projectUpdate.cnt_like += 1;
    } catch (e) {
      console.log(e);
    }
  };

  const removeLikeFromProject = async (projectID) => {
    try {
      await ProjectService.removeLike(projectID);
      const projectUpdate = projects.value.find((project) => project.id === projectID);

      projectUpdate.like = false;
      projectUpdate.cnt_like -= 1;
    } catch (e) {
      console.log(e);
    }
  };

  return {
    projects,
    getProjectTopRepositories,
    resetProjectRepositories,
    addLikeToProject,
    removeLikeFromProject,
  };
}
