var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('transition-group',{staticClass:"search__list",attrs:{"name":"list","tag":"ul"}},_vm._l((_vm.selectedCategory),function(category){return _c('li',{key:category.id + category.name},[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.onSetCategory(category.id)}}},[_vm._v(_vm._s(category.name))])])}),0),_c('div',{staticClass:"search__form"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search.text),expression:"search.text"}],attrs:{"type":"text"},domProps:{"value":(_vm.search.text)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searchProject($event)},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.search, "text", $event.target.value)}}}),_c('button',{staticClass:"search__form-btn",on:{"click":_vm.searchProject}},[_c('span',{staticClass:"search__form-icon"})])]),_c('div',{staticClass:"search__category"},[_c('multiselect',{staticClass:"select_category",attrs:{"options":_vm.categories,"multiple":true,"searchable":false,"showLabels":true,"close-on-select":true,"clear-on-select":false,"preserve-search":true,"placeholder":"Category","label":"name","track-by":"id","preselect-first":false},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var values = ref.values;
var search = ref.search;
var isOpen = ref.isOpen;
return [(values.length && !isOpen)?_c('span',{staticClass:"multiselect__single"},[_vm._v(" "+_vm._s(values.length)+" selected ")]):_vm._e()]}}]),model:{value:(_vm.selectedCategory),callback:function ($$v) {_vm.selectedCategory=$$v},expression:"selectedCategory"}}),_c('multiselect',{staticClass:"select_category",attrs:{"options":[
        { id: 0, text: 'All' },
        { id: 1, text: 'Open' },
        { id: 2, text: 'Private' } ],"multiple":false,"searchable":false,"showLabels":true,"close-on-select":true,"clear-on-select":false,"preserve-search":true,"placeholder":"Status","label":"text","track-by":"id","preselect-first":false},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
      var values = ref.values;
      var search = ref.search;
      var isOpen = ref.isOpen;
return [(values.length && !isOpen)?_c('span',{staticClass:"multiselect__single"},[_vm._v(" "+_vm._s(values.length)+" selected ")]):_vm._e()]}}]),model:{value:(_vm.search.type),callback:function ($$v) {_vm.$set(_vm.search, "type", $$v)},expression:"search.type"}},[_c('template',{slot:"placeholder"},[_c('span',[_vm._v("Status")])])],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }