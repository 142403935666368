<template>
  <div>
    <transition-group name="list" tag="ul" class="search__list">
      <li v-for="category in selectedCategory" :key="category.id + category.name">
        <a
          href="#"
          @click.prevent="onSetCategory(category.id)"
        >{{category.name}}</a>
      </li>
    </transition-group>
    <div class="search__form">
      <input v-model="search.text" type="text" @keyup.enter="searchProject">
      <button class="search__form-btn" @click="searchProject">
        <span class="search__form-icon"></span>
      </button>
    </div>
    <div class="search__category">
      <multiselect
        class="select_category"
        v-model="selectedCategory"
        :options="categories"
        :multiple="true"
        :searchable="false"
        :showLabels="true"
        :close-on-select="true"
        :clear-on-select="false"
        :preserve-search="true"
        placeholder="Category"
        label="name"
        track-by="id"
        :preselect-first="false"
      >
        <template slot="selection" slot-scope="{ values, search, isOpen }">
          <span class="multiselect__single" v-if="values.length && !isOpen">
            {{ values.length }} selected
          </span>
        </template>
      </multiselect>
      <multiselect
        class="select_category"
        v-model="search.type"
        :options="[
          { id: 0, text: 'All' },
          { id: 1, text: 'Open' },
          { id: 2, text: 'Private' },
        ]"
        :multiple="false"
        :searchable="false"
        :showLabels="true"
        :close-on-select="true"
        :clear-on-select="false"
        :preserve-search="true"
        placeholder="Status"
        label="text"
        track-by="id"
        :preselect-first="false"
      >
        <template slot="selection" slot-scope="{ values, search, isOpen }">
          <span class="multiselect__single" v-if="values.length && !isOpen">
            {{ values.length }} selected
          </span>
        </template>
        <template slot="placeholder">
          <span>Status</span>
        </template>
      </multiselect>
    </div>
  </div>
</template>
<script>
import Multiselect from 'vue-multiselect';

export default {
  name: 'HomeContainerSearch',
  components: {
    Multiselect,
  },
  mixins: [],
  props: {
    categories: {
      type: Array,
    },
    type: {
      type: String,
      default: 'group',
    },
    styleGroup: {
      type: Object,
    },
  },
  data() {
    return {
      projects: null,
      search: {
        category_id: [],
        text: '',
        type: null,
        // 'per-page': 50,
      },
      typeLabel: 'Status',
      selectedCategory: [],
    };
  },
  computed: {},
  created() {},
  methods: {
    onSetCategory(categoryID) {
      this.selectedCategory = this.selectedCategory
        .filter((cat) => cat.id !== categoryID);
    },
    onSetSearchType(type) {
      this.typeLabel = type.text;
      this.search.typeLabel = type.text;

      this.search = { ...this.search, type: type.id };
    },
    searchProject() {
      if (this.type === 'project') {
        this.$router.push({
          name: 'strategyProjectsPage',
          params: { searchParams: this.search },
        });
      } else {
        this.search.style = this.styleGroup;

        this.$router.push({
          name: 'PrivateGroupsPage',
          params: { searchParams: this.search },
        });
      }
    },
  },
  mounted() {},
  watch: {
    'search.text': function searchText(text) {
      this.search = { ...this.search, text };
    },
    selectedCategory(newCategory) {
      this.search.selectedCategory = newCategory;
      this.search = { ...this.search, category_id: newCategory.map((cat) => cat.id) };
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
::v-deep .multiselect__option--highlight {
  background: #489dd9;
  outline: none;
  color: #fff;
}

::v-deep  .multiselect__content-wrapper {
  min-width: 200px;
  width: auto;
}

::v-deep span.multiselect__option.multiselect__option--highlight::after {
  background: #489dd9;
}
::v-deep .multiselect__tag {
  background: #489dd9;
}
.page-layout {
  background-image: none !important;
}

.select_category {
  max-width: 140px;
  margin-right: 15px;
}

::v-deep .multiselect__tags {
  height: 45px;
  overflow: hidden;

  background-color: #f2f4f6;

  color: #000;
  font-size: 16px;
  font-weight: 400;

  padding: 9.5px 40px 9.5px 15px;
  border-width: 1px;
  border-radius: 10px;
  box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
}

::v-deep .multiselect__select {
  height: 22px;
  top: 13px;
}

::v-deep .multiselect__single {
  background-color: #f2f4f6;
}

::v-deep .multiselect__select:before {
  top: 90%;
  color: #000;
  border-color: #000 transparent transparent;
  border-width: 11px 6px 0;
}

::v-deep .multiselect__placeholder {
  font-size: 16px;
  padding-top: 0;
  color: #000;
}

::v-deep .multiselect__single {
  margin-top: 3px;
  color: #000;
}

::v-deep .search__category .btn-filter {
  height: 45px;
  border: none;
}

::v-deep .search__category .btn-filter::after {
  top: 10px;
}

.search__category {
  ::v-deep .btn-filter {
    font-size: 16px;
    font-weight: 400;
    padding: 9.5px 40px 9.5px 15px;
    border-width: 1px;
  }
}

.list-item {
  display: inline-block;
  margin-right: 10px;
}
.list-enter-active, .list-leave-active {
  transition: all 1s;
}
.list-enter, .list-leave-to /* .list-leave-active до версии 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}
</style>
