<template>
  <section class="partners" style="background: #FFFFFF">
    <div class="container">
      <h2 v-if="content" class="partners__title">{{ content.title }}</h2>
      <div v-if="content" class="partners__content">{{ content.text }}</div>
      <a href="#">
        <img
          class="partners-logo__img"
          srcset="@/assets/images/partners-logo-descktop.svg 320w,
              @/assets/images/partners-logo-descktop.svg 480w,
              @/assets/images/partners-logo-descktop.svg 800w"
          src="@/assets/images/partners-logo-descktop.svg"
          alt="WeThink Partners"
        >
      </a>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Partners',
  mixins: [],
  components: {},
  props: {
    content: {
      type: Object,
    },
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {
  },
};
</script>
