import { ref, onMounted } from '@vue/composition-api';

import DictionaryService from '@/services/DictionaryService';

export default function useCategoryRepositories() {
  const categories = ref([]);
  const getCategoryRepositories = async () => {
    let res = null;
    try {
      res = await DictionaryService.categories();
      categories.value = res.data;
    } catch (e) {
      categories.value = [];
    }
  };

  onMounted(getCategoryRepositories);

  return {
    categories,
    getCategoryRepositories,
  };
}
