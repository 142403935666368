<template>
  <section class="section-primary"
    style="padding-bottom: 0;">
    <div class="container">
      <div :class="hasSearchSlot ? 'section-primary__inner' : 'section-primary-row'"
        style="margin-bottom: 0;">
        <div class="">
          <div class="section-primary__title">
            <span class="yellow">Mission we </span>
            THINK. Renewable Energy Platform:
          </div>
          <div>
            <span class="yellow">
              <strong>Brings </strong>
            </span>together <span class="yellow">
              <strong>Energy intensive companies</strong>
            </span> (EIC) (steel, glass, paper, cement, building materials, etc).
            <ul class="ul-bullet">
              <li><strong>with suppliers</strong> of renewable energy products. </li>
              <li><strong>with Energy consultancies</strong>
                , technical engineers, and project financing institutions.
              </li>
              <li>
                <strong>to enable EICs to find and compare offers when</strong>
                 buying renewable energy projects.
              </li>
              <li>
                <strong>to enable  Energy suppliers to find leads and sell renewable</strong>
                 energy products.
              </li>
            </ul>
          </div>

          <div class="video">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/Ipm6DPlbqZ8?si=a8zxIUYMvlYwX20u" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          </div>
        </div>
        <slot name="search"></slot>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'HomeContainerIntro',
  data() {
    return {
      hasSearchSlot: false,
    };
  },
  mounted() {
    this.hasSearchSlot = !!this.$slots.search;
  },
};
</script>
