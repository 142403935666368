<template>
  <div style="color: black">
    <HomeContainerIntro>
      <template v-slot:search>
        <HomeContainerSearch :categories="categories" />
      </template>
    </HomeContainerIntro>
    <HomeContainerCards
      :categories="categories"
      :cmsContent="projectContent"
      :items="projects"
    >
      <template v-slot:card>
        <HomeProjectCard
          v-for="project in projects"
          :key="project.id + project.name"
          :item="project"
          @removeLike="removeLikeFromProject"
          @addLike="addLikeToProject"
          @showModal="showLikeModal"
        />
      </template>
    </HomeContainerCards>
    <HomeContainerCards
      v-for="(style, index) in styles"
      :key="style.id + style.color"
      :categories="categories"
      :cmsContent="groupContent[index]"
      :items="groupByStyle[style.id]"
      :styleContainer="index % 2 === 0 ? 'bg-white' : ''"
    >
      <template v-slot:search>
        <HomeContainerSearch
          :categories="categories"
          :styleGroup="style"
          @onSearch="getGroups($event, style)"
          @onResetItems="resetGroup(style)"
        />
      </template>
      <template v-slot:card>
        <HomeGroupCard
          v-for="group in groupByStyle[style.id]"
          :key="group.id + group.name + style.id + style.color"
          :item="group"
          style="width: 100%"
        />
      </template>
    </HomeContainerCards>
    <div v-if="!isAuth" class="join-banner">
      <div class="container">
        <div class="join-banner__inner">
          <div class="join-banner__wrapper">
            <div v-if="contentJoinUs" class="join-banner__title">
              {{contentJoinUs.title}}
            </div>
            <div
              v-if="contentJoinUs"
              class="join-banner__describe"
              style="color: #ffffff"
              v-html="contentJoinUs.text"
            ></div>
            <router-link
              :to="{ name: 'register' }"
              class="btn btn-primary"
            >Sign-up now</router-link>
          </div>
          <div class="join-banner__img">
            <img src="@/assets/images/bg-collage-join.svg" alt="">
          </div>
        </div>
      </div>
    </div>
    <Partners :content="contentPartners"/>
    <Modal
      @close="hideModal(modal.id)"
      :id="modal.id"
      :title="modal.text"
      :buttonTextOk="modal.btnCaption"
      :buttonVariantOk="modal.variant"
      :showButtonCancel="true"
      @cancel="hideModal(modal.id)"
      @ok="runModalActionMethod(modal.action)"
    />
  </div>
</template>
<script>
import useGroupRepositories from '@/composables/useGroupRepositories';
import useProjectRepositories from '@/composables/useProjectRepositories';
import useStyleRepositories from '@/composables/useStyleRepositories';
import useCategoryRepositories from '@/composables/useCategoryRepositories';

import HomeContainerIntro from '@/views/authorization/components/homeContainers/HomeContainerIntro.vue';
import HomeContainerCards from '@/views/authorization/components/homeContainers/HomeContainerCards.vue';
import HomeContainerSearch from '@/views/authorization/components/homeContainers/HomeContainerSearch.vue';
import HomeProjectCard from '@/views/authorization/components/homeContainers/HomeProjectCard.vue';
import HomeGroupCard from '@/views/authorization/components/homeContainers/HomeGroupCard.vue';
import Partners from '@/views/authorization/components/Parthers.vue';

import Modal from '@/components/Modal.vue';

import content from '@/mixins/content';
import modal from '@/mixins/modal';

export default {
  name: 'MainPage',
  components: {
    HomeContainerIntro,
    HomeContainerCards,
    HomeContainerSearch,
    HomeProjectCard,
    HomeGroupCard,
    Partners,
    Modal,
  },
  mixins: [content, modal],
  setup() {
    const {
      projects,
      getProjectTopRepositories,
      resetProjectRepositories,
      addLikeToProject,
      removeLikeFromProject,
    } = useProjectRepositories();
    const { groups, getGroupTopRepositories, resetGroupRepositories } = useGroupRepositories();
    const { categories } = useCategoryRepositories();
    const { styles } = useStyleRepositories();

    return {
      categories,
      styles,
      projects,
      getProjectTopRepositories,
      resetProjectRepositories,
      groups,
      getGroupTopRepositories,
      resetGroupRepositories,
      addLikeToProject,
      removeLikeFromProject,
    };
  },
  data() {
    return {
      features: {
        title: ['Groups', 'Strategy Project', 'Social Network'],
        defaultText: [
          'Lorem ipsum dolor sit amet, consectetuer',
          'Lorem ipsum dolor sit amet, consectetuer Lorem ipsum dolor sit amet, consectetuer',
          'Lorem ipsum dolor sit amet, consectetuer',
        ],
        link: ['/groups', '/projects', '/'],
      },
      cmsContent: [
        {
          title: 'Top Strategy Groups',
          text: `Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh
            euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad
            minim veniam, quis no.`,
        },
        {
          title: 'Top Showrooms',
          text: `Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh
            euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad
            minim veniam, quis no.`,
        },
        {
          title: 'Top Learning Platform',
          text: `Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh
            euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad
            minim veniam, quis no.`,
        },
      ],
      projectContent: {
        title: 'Top Strategy Projects',
        text: `Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh
          euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad
          minim veniam, quis no.`,
      },
      groupContent: [],
      contentJoinUs: null,
      contentPartners: null,
      groupByStyle: [],
      modal: {
        id: 'authorize',
        variant: 'danger',
        title: '',
        text: '',
        btnCaption: 'Login',
        userID: 0,
        type: '',
        action: '',
      },
    };
  },
  computed: {
    isAuth() {
      return this.$store.getters.getAuthUser;
    },
  },
  created() {
    this.getProjectTopRepositories();
  },
  methods: {
    showLikeModal(data) {
      this.modal.title = data.title;
      this.modal.text = data.text;
      this.modal.action = 'goToPageLogin';

      this.showModal(this.modal.id);
    },
    goToPageLogin() {
      this.$router.push({ name: 'login' });
    },
    async getGroups(searchData, style) {
      /* eslint-disable no-param-reassign */
      searchData.style = style.id;
      this.$set(this.groupByStyle, style.id, await this.getGroupTopRepositories(searchData));
    },
    resetGroup(style) {
      this.$set(this.groupByStyle, style.id, []);
    },
  },
  mounted() {
    document.body.classList.remove('page-layout');
    document.body.style.background = '';
  },
  watch: {
    styles() {
      this.styles.forEach(async (style) => {
        const res = await this.getGroupTopRepositories({ style: style.id });
        this.$set(this.groupByStyle, style.id, res);
      });
    },
    content() {
      if (!this.content) return;
      const { length } = this.content.block;

      this.projectContent = {
        title: this.content.block[0],
        text: this.content.block[1],
      };

      this.contentJoinUs = {
        title: this.content.block[length - 4],
        text: this.content.block[length - 3],
      };

      this.contentPartners = {
        title: this.content.block[length - 2],
        text: this.content.block[length - 1],
      };

      this.content.block.pop();
      this.content.block.pop();
      this.content.block.pop();
      this.content.block.pop();
      this.content.block.shift();
      this.content.block.shift();

      for (let i = 0; i < this.content.block.length; i += 2) {
        this.groupContent[i / 2] = {
          title: this.content.block[i],
          text: this.content.block[i + 1],
        };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.black_color {
  color: #000000;
}
</style>
