import axios from '@/libs/axios';

export default {
  /**
   * Send request for get categories
   * @param {Object} data
   * @returns {Object}
   */
  async categories() {
    const res = await axios.get('dictionaries/category');
    return res;
  },

  /**
   * Send request for get countries
   * @param {Object} data
   * @returns {Object}
   */
  async countries() {
    const res = await axios.get('dictionaries/countries');
    return res;
  },

  /**
   * Send request for get styles
   * @param {Object} data
   * @returns {Object}
   */
  async styles() {
    const res = await axios.get('dictionaries/style');
    return res;
  },

  /**
   * Send request for get styles
   * @param {Object} data
   * @returns {Object}
   */
  async postTypes() {
    const res = await axios.get('dictionaries/postTypes');
    return res;
  },

  /**
   * Send request for get styles
   * @param {Object} data
   * @returns {Object}
   */
  async postFilters() {
    const res = await axios.get('dictionaries/postFilters');
    return res;
  },

  /**
   * Send request for get styles
   * @param {Object} data
   * @returns {Object}
   */
  async postCategories() {
    const res = await axios.get('dictionaries/postCategories');
    return res;
  },

  /**
   * Send request for get styles
   * @param {Object} data
   * @returns {Object}
   */
  async postIdCategories(id) {
    const res = await axios.get(`dictionaries/postCategories/${id}`);
    return res;
  },

  async getChannelsCategories() {
    const res = await axios.get('dictionaries/channels');
    return res;
  },
};
