<template>
  <div class="top-projects__item">
    <div class="main-projects__item">
      <div class="projects__content">
        <div class="top-projects__top">
        <span class="action-like-num"
              style="padding-right:15px;color:white;">{{ item.cnt_like }}</span>
          <a
            href="#"
            class="top-projects__like"
            :title="this.titleLikes"
            @click.prevent="onHandleLike"
          >
            <span v-if="item.like" class="icon-like--active"></span>
            <span v-else class="icon-like--notactive"></span>
          </a>
        </div>
        <div class="top-projects__title">
          <h3>{{ item.name }}</h3>
        </div>
        <div class="top-projects__body">
          <div class="top-avatar__wrapper">
            <div class="top-avatar__img">
              <img v-if="item.author.avatar" :src="item.author.avatar" alt="Avatar">
              <img v-else src="@/assets/images/avatar.svg" alt="Avatar">
            </div>
            <div class="top-avatar__info">
              <div class="top-avatar__author">{{ item.author.name }}</div>
              <div class="top-avatar__time">{{ item.created_at }}</div>
            </div>
          </div>
          <ul class="category__list">
            <li class="category__item">
              <a class="category__color--1" style="cursor: default">{{ item.category_id }}</a>
            </li>
            <li class="category__item">
              <a class="category__color--2" style="cursor: default">{{ item.style_name }}</a>
            </li>
            <li class="category__item">
              <a class="category__color--3" style="cursor: default">{{ item.type }}</a>
            </li>
          </ul>
          <hr>
          <div class="top-projects__text" style="color: #000000">{{ item.text_short }}</div>
        </div>
      </div>
      <div class="top__bottom">
        <router-link
          :to="{ name: 'strategyProjectPageView', params: { id: item.slug } }"
          class="btn btn-primary">
          <i class="icon icon-profile"></i>View now
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import me from '@/mixins/me';

export default {
  name: 'HomeProjectCard',
  components: {},
  mixins: [me],
  props: {
    item: {
      type: Object,
    },
  },
  data() {
    return {};
  },
  computed: {
    titleLikes() {
      if (this.item.cnt_like === 0 || this.item.cnt_like === 1) return `${this.item.cnt_like} like`;

      return `${this.item.cnt_like} likes`;
    },
  },
  created() {
  },
  methods: {
    onHandleLike() {
      if (!this.me) {
        this.$emit('showModal', {
          title: '',
          text: 'Please login to like',
        });

        return;
      }

      if (this.item.like) {
        this.$emit('removeLike', this.item.id);
      } else {
        this.$emit('addLike', this.item.id);
      }
    },
  },
  mounted() {
  },
};
</script>

<style lang="scss" scoped>
.main-projects__item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
</style>
