<template>
  <div class="top-groups__item">
    <div class="main-projects__item">
      <div class="projects__content">
        <div class="top-groups__head color--1" :style="{ backgroundColor: item.style_color }">
          <div class="top-groups__title">{{ item.name || '' }}</div>
          <div class="top-groups__avatar-wrapper">
            <div class="top-groups__avatar color--1" :style="{ borderColor: item.style_color }">
              <img
                v-if="item.users.length && item.users[0].avatar"
                :src="item.users[0].avatar"
                alt="Avatar"
              >
              <img v-else src="@/assets/images/avatar.svg" alt="Avatar">
            </div>
          </div>
        </div>
        <div class="top-groups__body">
          <ul class="category__list">
            <li class="category__item">
              <a class="category__color--1" href="">{{ item.category_id }}</a>
            </li>
            <li class="category__item"><a class="category__color--2" href="">{{ item.type }}</a>
            </li>
          </ul>
          <ul class="top-groups__statistic">
            <li><strong>{{ item.cnt_project }}</strong> Strategy Projects</li>
            <li><strong>{{ item.cnt_user }}</strong> Members</li>
          </ul>
          <hr>
          <div class="top-avatar__wrapper">
            <div class="top-avatar__img">
              <img
                v-if="item.users.length && item.users[0].avatar"
                :src="item.users[0].avatar"
                alt="Avatar"
              >
              <img v-else src="@/assets/images/avatar.svg" alt="Avatar">
            </div>
            <div class="top-avatar__info">
              <div
                v-if="item.users.length && item.users[0].name"
                class="top-avatar__author"
                style="color: #000"
              >Moderator: {{ item.users[0].name }}
              </div>
              <div v-else class="top-avatar__author" style="color: #000">Moderator:</div>
            </div>
          </div>
          <div class="top-groups__text">{{ item.description }}</div>
          <hr>
        </div>
      </div>
      <div class="top__bottom">
        <router-link
          :to="{ name: 'profileGroupsView', params: { id: item.slug } }"
          class="btn btn-primary">
          <i class="icon icon-profile"></i>View now
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'HomeGroupCard',
  components: {},
  mixins: [],
  props: {
    item: {
      type: Object,
    },
  },
  data() {
    return {};
  },
  computed: {},
  created() {
  },
  methods: {},
  mounted() {
  },
};
</script>

<style lang="scss" scoped>
.main-projects__item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
</style>
