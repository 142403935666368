import { ref, onMounted } from '@vue/composition-api';

import DictionaryService from '@/services/DictionaryService';

export default function useStyleRepositories() {
  const styles = ref([]);
  const getStyleRepositories = async () => {
    let res = null;
    try {
      res = await DictionaryService.styles();
      styles.value = res.data;
    } catch (e) {
      styles.value = [];
    }
  };

  onMounted(getStyleRepositories);

  return {
    styles,
    getStyleRepositories,
  };
}
